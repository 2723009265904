/* istanbul ignore file */
export const QUERY_KEYS = {
  CONFIG_CAT: 'tanks-feature-flags',
};

export const FEATURE_FLAGS = {
  HELP_BUTTON: 'helpButton',
  MULTI_REGION: 'multiRegions',
  NOTIFICATIONS_BUTTON: 'notificationsButton',
  POST_HOG_ANALYTICS: 'PostHogAnalytics',
  QUALAROO_NPS: 'qualarooNps',
  TALKDESK: 'talkdesk',
};

export const SESSION_STORAGE = {
  POST_HOG_TOOLBAR: 'posthog_toolbar',
};

export const LOCAL_STORAGE = {
  APPS_COLLAPSED: 'app_sidebar_collapsed',
};
