import { SESSION_STORAGE } from './constants';

const initializeApp = async () => {
  const { default: initializeGlobalVars } = await import(
    '@anova/crude-vars/src/initialize-globals'
  );
  initializeGlobalVars();

  // @ts-ignore
  window.MICRO_FRONTEND = true;

  const [renderer, appModule] = await Promise.all([
    import('./shared/modules/renderer'),
    import('./App'),
  ]);

  // Save PostHog Toolbar info before
  const toolbarJSON = new URLSearchParams(
    window.location.hash.substring(1)
  ).get('__posthog');
  if (toolbarJSON) {
    window.sessionStorage.setItem(
      SESSION_STORAGE.POST_HOG_TOOLBAR,
      toolbarJSON
    );
  }

  const baseRenderer = renderer.default;
  const App = appModule.default;
  baseRenderer(App);
};

initializeApp();

/**
 * @exports
 * Initialization method for testing purposes
 */
export const testInit = initializeApp;

export default undefined;
